<template>
    <div class="reporting">
        <div style="display: flex;">
          <Headbuttom></Headbuttom>
           <el-button type="primary" style="margin-left:20px" @click="onPlus" size="small">
             <i class="el-icon-plus" />新增
            </el-button>
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input>
        </div>
       
        </div>

        <div class="attheadfrom" v-if="flag">
      <el-form
        :inline="true"
        size="small"
        :model="searchParams"
        class="demo-form-inline"
      >
        <el-form-item label="姓名">
          <el-input
            v-model="searchParams.name"
            
          ></el-input>
        </el-form-item>
        <el-form-item label="企业" v-if="UserInfo.is_dinner_sys">
            <el-select size='small' v-model="depid" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="部门">
            <wlTreeSelect
              leaf
              width="180"
              checkbox
              :data="treeData"
              @change="handleCheckChange"
              v-model="selected"
              :showTotal="2"
              ></wlTreeSelect>
        </el-form-item>

        <el-form-item label="报餐时间">
                <el-date-picker
                v-model="searchParams.tiemes"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['12:00:00', '08:00:00']">
                </el-date-picker>
        </el-form-item>

         <el-form-item label="工号">
          <el-input
            v-model="searchParams.number"
            
          ></el-input>
        </el-form-item>
         <el-form-item label="手机号码">
          <el-input
            v-model="searchParams.mobile"
            
          ></el-input>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("devtable.cx")
          }}</el-button>
        </el-form-item>
      </el-form>
        </div>

      <Tables @handlerow="handlerow"
      @handlpages="handlpages"
      @handleSizeChange="handleSizeChange"
      :tableLabel="tableLabel"
      :tableData="tableData"
      :flags="flags"
      :operation="operation"
      :configs="configs"></Tables>


      <Report ref="report" />

      <Reportedit ref="reportedit" />

    </div>
</template>

<script>
import Tables from '@/components/table'
import Select from '@/components/selectree/wltreeselect'
import Report from './commponents/reportdlog'
import Reportedit from './commponents/repordlogedit'
import {formatDate} from '@/utils/date.js'
export default {
    components:{
        Select,
        Tables,
        Report,
        Reportedit
    },
    data(){
        return{
            options: [],
            depid: '',
            treeData: [],
            flag:false,
            searchParams:{
                name:'',
                serial_number:'',
                times:'',
                dept_id:'',
                type:'-1',
            },
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
            tableLabel:[
            
            {
              prop: "user_id",
              label: '注册id',
              align: "center",
            },
            {
              prop: "name",
              label: '姓名',
              align: "center",
            },
            {
              prop: "number",
              label: '工号',
              align: "center",
            },
            {
              prop: "dept_name",
              label: '企业部门',
              align: "center",
            },
            
            {
              prop: "mobile",
              label: '手机号码',
              align: "center",
            },
            {
              prop: "apply_time",
              label: '预定日期',
              align: "center",
            },
            {
              prop: "type",
              label: '报餐类型',
              align: "center",
            },
            ],
            tableData:[],
            operation:{
              width:150,
              data:[
                {
                  name: "修改",
                  type: "success",
                },
                {
                name: "删除",
                  type: "danger",
                },
                
              ]
            },
            // 分页数据
            configs: {
              page_index:1,
              pagesize: 10,
              total: 20,
              loading: false, // 加载状态
            },
            flags: {
              show: false, // 多选是否显示
              ishow: true, // 序号是否显示
            },
            selected: [], // 树下拉框选中数据
        }
    },
    watch:{
        'depid':function(nev,old){
            
            this.getdeplist(nev)
        }
    },
    mounted(){
       if(this.UserInfo.is_dinner_sys==1){
            this.handlentpw()
        }else{
            this.getdeplist()
        }

      this.getReportlist()
      this.$refs.report.handEmplya()
    },
    methods:{

        // 查询企业
        handlentpw(){
            let data={
                method:'co.dinner.enterprise.list',
                agent_id:this.UserInfo.agent_id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.options = res.data.data
                }
            })
        },
        getdeplist(agent_id) {
            let data = {
                method: "co.dept.list",
                agent_id:agent_id?agent_id:this.UserInfo.agent_id,
                manager_id: this.UserInfo.manager_id,
            };
            this.$serve(data).then((res) => {
                var result = [];
                if (res.data.data != null) result.push(this.getFirstObj(res.data.data));
                this.treeData = result
            });
        },
        getFirstObj(obj) {
            for (var key in obj) {
                return obj[key];
            }
        },

        handFromshow () {
            this.flag = !this.flag
        },
        onPlus(){
            this.$refs.report.dialogVisible = true
            // 
        },
        // 报餐列表
        getReportlist(v,va){
          let data={
            method:'co.dinner.list',
            page_index:v?v:1,
            page_size:va?va:10,
            agent_id:this.UserInfo.agent_id,
            dept_id:this.searchParams.dept_id,
            is_dinner_sys:this.UserInfo.is_dinner_sys,
            enterprise_id:this.depid?this.depid:0,
            name:this.searchParams.name,
            mobile:this.searchParams.mobile,
            start_time:'',
            end_time:'',
            type:''
          }
          this.$serve(data).then(res=>{
              if(res.data.code==0){
                  this.tableData = res.data.data
                  this.configs.total = res.data.count
     
                  this.tableData.forEach(el=>{
                    el.apply_time = formatDate(new Date(el.apply_time * 1000), 'yyyy-MM-dd')
                    
                  })
              }
          })
        },
        // 获取企业部门id
        handleCheckChange(val){
            let dept_id=[]
            val.forEach(el=>{
                dept_id.push(el.id)
            })
            this.searchParams.dept_id = dept_id.join(",");
            console.log(`677`,this.searchParams.dept_id)
        },
        // 表格按钮
        handlerow(v,name){
            if(name=='删除'){
              this.$confirm('是否删除', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(() => {
                  let data={
                      method:'co.dinner.del',
                      id:v.id
                  }
                this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.$message({
                    type: 'success',
                    message: '删除成功!'
                    });
                    this.getReportlist()
                }else{
                    this.$message({
                      type: 'error',
                      message: '删除失败!'
                      });
                  }
                })
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消删除'
                });          
            });
            }else{
           
              this.$refs.reportedit.handleGetrep(v.id)
              this.$refs.reportedit.dialogVisible = true
            }
        },
        // 分页
        handlpages(v){
          
          this.getReportlist(v)
        },
        handleSizeChange(va){
          this.getReportlist(1,va)
        },
        onSubmit(){
          this.getReportlist()
        }
    }
}
</script>

<style lang="less" scoped>
.reporting{
    padding: 20px;
    background-color: #fff;
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
 
</style>