<template>
    <div class="conts"> 
        <!-- <el-button @click="ss">阀手动阀</el-button> -->
        <el-col :span="10">
            <el-table
                :data="data1"
                border
                ref="selection" 
                @selection-change="checkAll"
                height="220"
                style="width: 100%">
                <el-table-column
                type="selection"
                width="40">
                </el-table-column>

                <el-table-column
                v-for="(item,index) in Tables"
                    :prop="item.prop"
                    :label="item.label"
                    :key="index"
                >
                </el-table-column>
                
            </el-table>

            <div style="margin-top:10px">
                <el-pagination
                @current-change="handleCurrentChange"
                small
                layout="prev, pager, next"
                :total="total">
                </el-pagination>
            </div>

        </el-col>
        <el-col :span="4">
        <div class="opSetting">
            <div @click="handelSelect">
                <el-button icon="el-icon-d-arrow-right" :disabled="nowSelectData.length?false:true" :size="buttonSize" type="primary" >
                    
                </el-button>
            </div>
            <div class="spacing" style="margin-top:10px" @click="handleRemoveSelect">
                <el-button icon="el-icon-d-arrow-left" :disabled="nowSelectRightData.length?false:true" :size="buttonSize" type="primary">
                    
                </el-button>
            </div>
        </div>
        </el-col>
    
        <el-col :span="10">
            <el-table
                :data="selectArr"
                border
                ref="selection" 
                height="220"
                @selection-change="checkRightAll"
                style="width: 100%"
            >
                <el-table-column
                type="selection"
                width="40">
                </el-table-column>

                <el-table-column
                v-for="(item,index) in Tables"
                    :prop="item.prop"
                    :label="item.label"
                    :key="index"
                >
                </el-table-column>
                
                
                
            </el-table>
            <!-- <div style="margin-top:10px">
                <el-pagination
                small
                layout="prev, pager, next"
                :total="total">
                </el-pagination>
            </div> -->

        </el-col>
    </div> 
</template>
<script>
export default {
    props:{
        tabledata:Array,
        Tables:Array,
        total:Number
    },
    data(){
        return{
            selectArr:[],  // 右边列表
            buttonSize: 'large',
            options: [],

            value: '',
            data1:[],
            data2:[],
            nowSelectData: [], // 左边选中列表数据
            nowSelectRightData: [], // 右边选中列表数据
        }
    },
    watch:{
        'tabledata':function(old,nes){
            if(old != nes){
                this.data1 = this.tabledata
                this.selectArr=[]
                // this.nowSelectData=[]
            }
        }
    },

    mounted(){
        this.data1 = this.tabledata
        this.nowSelectRightData=[]
    },

    methods:{
        // 分月
        handleCurrentChange(val){
            this.selectArr =[]
            this.nowSelectRightData=[]
            this.$emit('handleCurrentChange',val)
            
        },
        checkAll(val){
            console.log(`90`,val)
            this.nowSelectData = val;
        },
        checkRightAll(val) {
            this.nowSelectRightData=[]
            this.nowSelectRightData = val;
            console.log(this.nowSelectRightData)
            this.$emit('checkRightAll',val)
        },
        // 选中
        handelSelect(){   
            // console.log(`1`)
            this.nowSelectRightData = this.nowSelectRightData.concat(this.nowSelectData)
            this.selectArr = this.handleConcatArr(this.selectArr, this.nowSelectData) 
            this.handleRemoveTabList(this.nowSelectData,  this.data1);
            this.nowSelectData = [];
            console.log(`80`,this.nowSelectRightData)
            this.$emit('handelSelect',this.nowSelectRightData)
        },
        ss(){
            this.selectArr=[]
            this.nowSelectRightData=[]
        },
        // 取消
        handleRemoveSelect() {

            this.data1 = this.handleConcatArr(this.data1, this.nowSelectRightData) 

            this.handleRemoveTabList(this.nowSelectRightData,  this.selectArr);
            this.nowSelectRightData = [];
        },
        handleConcatArr(selectArr, nowSelectData) {
            let arr = [];
            arr = arr.concat(selectArr, nowSelectData);   
            return arr;
        },
        handleRemoveTabList(isNeedArr,  originalArr) {
            if(isNeedArr.length && originalArr.length) {
                for(let i=0; i<isNeedArr.length; i++) {
                    for(let k=0; k<originalArr.length; k++) {
                    if(isNeedArr[i]["name"] === originalArr[k]["name"]) {
                        originalArr.splice(k, 1);   
                    }
                    }
                }
            }
        }
    }
}
</script>
<style scoped>
.opSetting{
    text-align: center;
    margin-top: 60px;
}
.conts ::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }
 
    /* 表格滚动条的滑块*/
    .conts ::-webkit-scrollbar-thumb {
        background-color: #409EFF;
        border-radius: 3px;
    }
    /**修改全局的滚动条*/
    /**滚动条的宽度*/
    .conts ::-webkit-scrollbar {
        width: 5px;/*竖向*/       
        height: 5px;/*横向*/
        background-color: white;
    }
 
    /*滚动条的滑块*/
   .conts ::-webkit-scrollbar-thumb {
        background-color: #409EFF;
        border-radius: 3px;
    }
</style>