<template>
  <div>
    <wlTreeSelect
      leaf
      width="180"
      checkbox
      :data="treeData"
      @change="handleCheckChange"
      v-model="selected"
      :showTotal="2"
			:placeholder="$t('devtable.qxz')"
    ></wlTreeSelect>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // value: [], // 选中值
      props: {
        label: "name",
        value: "id",
      }, // 配置
      treeData: [],
      selected: [], // 树下拉框选中数据
    };
  },
  mounted() {
    
    this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
    this.getdeplist();
  },
  watch:{
      'selected':function(nev,old){
        //   if(nev != old){
        //       this.selected == []
        //       this.selected = nev
        //   }
          console.log(`98`,nev,old)
      }
  },
  methods: {
    getdeplist() {
      let data = {
        method: "co.dept.list",
        agent_id: this.UserInfo.agent_id,
        manager_id: this.UserInfo.manager_id,
      };
      this.$serve(data).then((res) => {
        var result = [];
        if (res.data.data != null) result.push(this.getFirstObj(res.data.data));
        this.treeData = result
      });
    },
    getFirstObj(obj) {
      for (var key in obj) {
        return obj[key];
      }
    },

    handleCheckChange(val) {
      console.log(`2`,val);
      console.log(this.selected);
      this.$emit('handleCheckChange',val)
    },
  },
};
</script>