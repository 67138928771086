<template>
  
  <el-dialog
    title="修改报餐"
    :visible.sync="dialogVisible"
    width="40%"
    append-to-body
    center=""
    :before-close="handleClose">

        <el-row>
            <el-col :span="12">
                <el-col :span="5">
                    <span class="spna">注册ID:</span>
                </el-col>
                <el-col :span="16">
                    <el-input size="small" v-model="user_id" placeholder="请输入内容"></el-input>
                </el-col>
                
            </el-col>
            <el-col :span="12">
                <el-col :span="6">
                    <span class="spna">预定日期:</span>
                </el-col>
                <el-col :span="16">
                    <el-date-picker
                    v-model="apply_time"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="timestamp">
                    </el-date-picker>
                </el-col>
            </el-col>
        </el-row>

        <el-row style="margin-top:20px">
            <el-col :span="12">
                <el-col :span="5">
                    <span class="spna">姓名:</span>
                </el-col>
                <el-col :span="16">
                    <el-input size="small" v-model="name" placeholder="请输入内容"></el-input>
                </el-col>
                
            </el-col>
            <el-col :span="12">
                <el-col :span="6">
                    <span class="spna">报餐类型:</span>
                </el-col>
                <el-col :span="16">
                    <el-checkbox-group v-model="type">
                        <el-checkbox label="早餐" name="type"></el-checkbox>
                        <el-checkbox label="中餐" name="type"></el-checkbox>
                        <el-checkbox label="晚餐" name="type"></el-checkbox>
                        <el-checkbox label="宵夜" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-col>
            </el-col>
        </el-row>

    <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="onsVisible">确 定</el-button>
    </span>
    </el-dialog>

</template>

<script>
export default {
    data(){
        return{
            dialogVisible:false,
            name:'',
            user_id:'',
            apply_time:'',
            type:[],
            id:''
        }
    },
    methods:{
        handleClose(){
            this.dialogVisible = false
        },
        // 查询当前的信息
        handleGetrep(id){
            this.id = id
            let data={
                method:'co.dinner.get',
                id:id
            }
            this.$serve(data).then(res=>{
              
                if(res.data.code==0){
                    let infolist = res.data.data
                    console.log(`09`,infolist)
                    this.name = infolist.name
                    this.user_id = infolist.user_id,
                    this.apply_time = infolist.apply_time*1000,
                    this.type = infolist.type.split('+')
                }
            })
        },
        // 保存
        onsVisible(){
            let type_str = []
            this.type.forEach(el=>{
                if(el=='早餐'){
                   type_str.push('0')
                }
                if(el=='中餐'){
                    type_str.push('1')
                }
                if(el=='晚餐'){
                    type_str.push('2')
                }
                if(el=='宵夜'){
                    type_str.push('3')
                }
            })
            let data={
                method:'co.dinner.update',
                id:this.id,
                apply_time:this.apply_time/1000,
                type: type_str.join(','),
                type_str:this.type.join('+')
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.$message({
                        message: "修改成功!",
                        type: "success",
                    });
                    this.$parent.getReportlist()
                    this.dialogVisible = false
                }else{
                    this.$message({
                        message: "修改成功!",
                        type: "error",
                    });
                }
            })
        }
    }
}
</script>

<style scoped>
.spna{
    display: inline-block;
    line-height: 30px;
}
</style>