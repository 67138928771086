<template>
    <div class="diolgs">
        <el-dialog
            title="报餐管理"
            :visible.sync="dialogVisible"
            width="52%"
            append-to-body
            :before-close="handleClose"
            center
            >
            <div class="row_cont" >
                <p class="tils">员工</p>
                <div style="display:flex;">
                    <div style="width:290px">
                        
                        <div style="display:flex">
                            <p>部门：</p>
                            <wlTreeSelect
                            leaf
                            width="180"
                            
                            :data="treeData"
                            @change="handleCheckChange"
                            v-model="selected"
                            :showTotal="2"
                            ></wlTreeSelect>
                        </div>

                        

                        <div style="display:flex;margin:20px 0 20px 0">
                            <el-input v-model="name" size="small" placeholder="示例：姓名" style="width:150px;margin-right:20px"></el-input>
                            <el-button type="primary" @click="handEmquer" size="small">查询</el-button>
                        </div>
                    </div>   
                    <div style="margin-left:90px">
                       
                        <p style="margin-top:62px">已选员工</p>
                    </div>
                    <div v-if="UserInfo.is_dinner_sys">
                        <span>企业:</span>
                        <el-select size='small' v-model="depid" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <Transtable ref="trantable2" @handleCurrentChange="handleCurrentChange" :total="total" @handelSelect="handelSelect" @checkRightAll="checkRightAll2" :tabledata="depList" :Tables="Tables"></Transtable>
            
            </div>


            <div class="quers">
               
                <el-date-picker
                :picker-options="pickerOptions"
                v-model="apply_time"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="timestamp">
                </el-date-picker>

                <div class="slep">
                    <span>报餐类型：</span>
                    <el-checkbox-group v-model="type_str" >
                        <el-checkbox label="早餐"  value="0"></el-checkbox>
                        <el-checkbox label="中餐"  value="1"></el-checkbox>
                        <el-checkbox label="晚餐" value="2"></el-checkbox>
                        <el-checkbox label="宵夜"  value="3"></el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>

       
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handalrModl">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Transtable from './transtable'
import Select from '@/components/selectree/wltreeselect'
export default {
    components:{
        Transtable,
        Select
    },
    data(){
        return{
            pickerOptions: { 
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的 
                }
            },
            dialogVisible:false,
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
            dept_id:'',
            Tables:[
                {
                    prop: "user_id",
                    label: '注册ID',
                },
                {
                    prop: "name",
                    label: '姓名',
                },
                {
                    prop: "number",
                    label: '工号',
                },
            ],
            depList:[],
            name:'',
            type: [],
            apply_time:new Date().getTime(),
            user_id:'',
            type_str:['中餐','晚餐'],
            options: [],
            depid: '',
            props: {
                label: "name",
                value: "id",
            }, // 配置
            treeData: [],
            selected: [], // 树下拉框选中数据
            total:null
        }
    },

    mounted(){



        if(this.UserInfo.is_dinner_sys==1){
            this.handlentpw()
        }else{
            this.getdeplist()
        }
       
    },

    watch:{
        'depid':function(nev,old){
            
            this.getdeplist(nev)
        }
    },

    methods:{
        // fenye
        handleCurrentChange(val){
            this.handEmplya(val)
        },
        getdeplist(agent_id) {
            let data = {
                method: "co.dept.list",
                agent_id:agent_id?agent_id:this.UserInfo.agent_id,
                manager_id: this.UserInfo.manager_id,
            };
            this.$serve(data).then((res) => {
                var result = [];
                if (res.data.data != null) result.push(this.getFirstObj(res.data.data));
                this.treeData = result
            });
        },
        getFirstObj(obj) {
            for (var key in obj) {
                return obj[key];
            }
        },
        // 查询企业
        handlentpw(){
            let data={
                method:'co.dinner.enterprise.list',
                agent_id:this.UserInfo.agent_id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.options = res.data.data
                }
            })
        },
        // 获取企业部门id
        handleCheckChange(val){
            let dept_id=[]
            val.forEach(el=>{
                dept_id.push(el.id)
            })
            this.dept_id = dept_id.join(",");
            console.log(`677`,this.dept_id)
        },
        // 部门人员列表
        handEmplya(val){
       
            let data={
                method:'co.dinner.employee.list',
                agent_id:this.UserInfo.agent_id,
                dept_id:this.dept_id,
                name:this.name,
                page_index:val?val:'1',
                page_size:10
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.depList = res.data.data
                    this.total = res.data.count
                }
            })
        },
        handelSelect(val){
            this.user_id=[]
            if(val.length>10){
                this.$message({
                    message: "一次只能添加10条!",
                    type: "error",
                });
            }
            // this.user_id=[]
            val.forEach(el=>{
                this.user_id.push(el.user_id)
            })
            console.log(this.user_id)
        },
        checkRightAll2(val){
           this.user_id=[]
            if(val.length>10){
                this.$message({
                    message: "一次只能添加10条!",
                    type: "error",
                });
            }
            // this.user_id=[]
            val.forEach(el=>{
                this.user_id.push(el.user_id)
            })
        },
        // 查询
        handEmquer(){
            this.handEmplya()
        },
        // 关闭弹窗
        handleClose(){
            this.dialogVisible = false
        },
        // 保存
        handalrModl(){
            let apply_time=[]
            let type = []
            let type_str = []
            this.type_str.forEach(el=>{
                if(el=='早餐'){
                    this.type.push('0')
                }
                if(el=='中餐'){
                    this.type.push('1')
                }
                if(el=='晚餐'){
                    this.type.push('2')
                }
                if(el=='宵夜'){
                    this.type.push('3')
                }
            })
            for(let i=0;i<this.user_id.length;i++){
                apply_time.push(this.apply_time/1000)
                type.push(this.type.join('|'))
                type_str.push(this.type_str.join('+'))
            }
            if(this.user_id.length>10){
                this.$message({
                    message: "一次只能添加10条!",
                    type: "error",
                });
                return false
            }

            if(!apply_time.length){
                this.$message({
                    message: "请选择报餐时间!",
                    type: "error",
                });
                return false
            } 
            if( apply_time[0] == 0 ){
                this.$message({
                    message: "请选择报餐时间!",
                    type: "error",
                });
                return false
            }
            let data = {
               method:'co.dinner.add',
               agent_id:this.UserInfo.agent_id,
               user_id:this.user_id.toString(),
               apply_time:apply_time.toString(),
               type:type.toString(),
               type_str:type_str.toString()
            }
            // console.log(data)
            // return false
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.$message({
                        message: "添加成功!",
                        type: "success",
                    });
                    this.$parent.getReportlist()
                    this.dialogVisible = false
                    
                }else{
                    this.$message({
                        message: "添加失败!",
                        type: "error",
                    });
                }
            })
          
        }

    }
}
</script>

<style lang="less" scoped>
.diolgs .el-dialog--center .el-dialog__body{
    padding: 25px 25px 0px;
}
.el-dialog__body {
    
    .row_cont{
        height: 350px;
        border: 1px solid #ccc;
        position: relative;
        padding:30px ;
        .tils{
            width: 80px;
            height: 30px;
            position: absolute;
            left: 10px;
            top: -10px;
            background-color:#fff ;
            text-align: center;
        }
    }
}

.el-dialog__body /deep/ .el-dialog__header{
    border-bottom: 1px solid #ccc;
}
.opSetting{
    text-align: center;
    margin-top: 80px;
}
 .quers{
     margin-top: 20px;
     display: flex;
    justify-content: space-around;
 }
 .slep{
     display: flex;
     line-height: 40px;
    .span{
        display: inline-block;
    }
 }   
</style>